import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';

function Study() {
  const { t } = useTranslation();
  return (
    <section className='section section-laight' id='education'>
      <div className='container'>
        <div className='heading'>
          <h2> {t('study.title')} </h2>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <ScrollAnimation animateIn='slideInLeft' animateOnce={true}>
              <div className='education-block'>
                <h5>{t('study.block1.years')}</h5>
                <i className='fas fa-graduation-cap glyphicon-education'></i>
                <h2>{t('study.block1.diploma')}</h2>
                <h3>{t('study.block1.titleDiplomat')}</h3>
                <h4>{t('study.block1.school')}</h4>
                <p>{t('study.block1.option')}</p>
                <p>{t('study.block1.score')}</p>
              </div>
            </ScrollAnimation>
          </div>
          <div className='col-md-6'>
            <ScrollAnimation animateIn='slideInRight' animateOnce={true}>
              <div className='education-block'>
                <h5>{t('study.block2.years')}</h5>
                <i className='fas fa-graduation-cap glyphicon-education'></i>
                <h2>{t('study.block2.diploma')}</h2>
                <h3>{t('study.block2.titleDiplomat')}</h3>
                <h4>{t('study.block2.school')}</h4>
                <br></br>
                <p>{t('study.block2.score')}</p>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Study;
