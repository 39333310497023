import React from 'react';
import Form from '../../components/form/Form';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';

function Contact() {
  const { t } = useTranslation();
  return (
    <section id='contactme' className='section section-light'>
      <div className='heading'>
        <h1>{t('contact.title')}</h1>
      </div>
      <div className='container text animatedParent' id='contact'>
        <ScrollAnimation animateIn='pulses' animateOnce={true}>
          <Form></Form>
        </ScrollAnimation>
      </div>
    </section>
  );
}

export default Contact;
