import React from 'react';
import Card from '../../components/card/Card';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';

function BackEnd() {
  const { t } = useTranslation();
  return (
    <div className='row sectionBack'>
      <div id='iconContainer' className='col col-md-2'>
        <i className='fas fa-database' id='icon'></i>
      </div>
      <div className='col-12 col-md-10'>
        <ScrollAnimation animateIn='fadeInRight' animateOnce={true}>
          <Card>
            <h5 className='card-title'>{t('skills.backEnd.title')}</h5>
            <h6 className='card-subtitle mb-2 text-muted'>
              {t('skills.backEnd.title')}
            </h6>
            <div className='row'>
              <img
                src='images/iconSkill/php.png'
                className='img-responsive col-4 col-sm-2'
                alt='logo html'
              />
              <img
                src='images/iconSkill/mysql.png'
                className='img-responsive col-4 col-sm-2'
                alt='logo html'
              />
              <img
                src='images/iconSkill/nodejs.png'
                className='img-responsive col-4 col-sm-2'
                alt='logo html'
              />
              <img
                src='images/iconSkill/java.png'
                className='img-responsive col-4 col-sm-2'
                alt='logo html'
              />
              <img
                src='images/iconSkill/postgre.png'
                className='img-responsive col-4 col-sm-2'
                alt='logo html'
              />
              <img
                src='images/iconSkill/python.png'
                className='img-responsive col-4 col-sm-2'
                alt='logo html'
              />
            </div>
          </Card>
        </ScrollAnimation>
      </div>
    </div>
  );
}

export default BackEnd;
