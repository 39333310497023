import React from 'react';
import NavBar from '../components/topbar/NavBar';
import Footer from '../components/footer/Footer';
import Skills from '../containers/skills/Skills';
import About from '../containers/about/About';
import Experience from '../containers/experiences/Experience';
import Study from '../containers/study/Study';
import Portfolio from '../containers/portfolio/Portfolio';
import Contact from '../containers/contact/Contact';
import BreakSection from '../components/section/BreakSection';
import { useTranslation } from 'react-i18next';

const DefaultLayout = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div id='wrapper'>
        <div id='content-wrapper' className='d-flex flex-column'>
          <div id='content'>
            <NavBar></NavBar>
            <About></About>
            <Skills></Skills>
            <BreakSection className='pximg1'>
              <div className='pxtext'>
                <p className='noMb'>{t('breakSection.text1')}</p>
              </div>
            </BreakSection>

            <Experience></Experience>
            <BreakSection className='pximg2 '></BreakSection>
            <Study></Study>
            <BreakSection className='pximg3'>
              <div className='pxtext'>
                <p>{t('breakSection.text3')}</p>
                <p className='noMb'>{t('breakSection.text3Bis')}</p>
              </div>
            </BreakSection>
            <Portfolio></Portfolio>
            <BreakSection className='pximg4'></BreakSection>
            <Contact></Contact>
            <BreakSection className='pximg5'></BreakSection>
          </div>
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DefaultLayout;
