import React from 'react';
import FrontEnd from './FrontEnd';
import BackEnd from './BackEnd';
import WebDesign from './WebDesign';
import { useTranslation } from 'react-i18next';

function Skills() {
  const { t } = useTranslation();
  return (
    <section id='skills' className='section section-light'>
      <div className='red-divider'></div>
      <div className='heading'>
        <h2> {t('skills.title')} </h2>
        <h3>{t('skills.subTitle')}</h3>
      </div>
      <FrontEnd />
      <BackEnd />
      <WebDesign />
    </section>
  );
}

export default Skills;
