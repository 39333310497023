import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import PropTypes from 'prop-types';

function TimeLineContainer({ entreprise, job, date, children }) {
  const titleEntreprise =
    entreprise === '' ? (
      <div></div>
    ) : (
      <h4 className='entreprise'>{entreprise}</h4>
    );
  return (
    //     <div className="timeline-panel-container">
    //     <Card>
    //         <div className="timeline-heading">
    //             <h3>{entreprise}</h3>
    //             <h4>{job}</h4>
    //             <p className="text-muted">
    //<small className="glyphicon glyphicon-time"></small>{date}</p>
    //         </div>
    //         <div className="timeline-body">
    //             {children}
    //         </div>
    //     </Card>
    // </div>
    <div className='timeline'>
      <ScrollAnimation animateIn='fadeInDown' animateOnce={true}>
        <div className='timeline-content'>
          <div className='timeline-icon'>
            <i className='fas fa-briefcase'></i>
          </div>
          <span className='timeline-year'>
            <span className='year'>{date}</span>
          </span>
          <h3 className='title'>{job}</h3>
          {titleEntreprise}
          <div className='description'>{children}</div>
        </div>
      </ScrollAnimation>
    </div>
  );
}

TimeLineContainer.propTypes = {
  children: PropTypes.object,
  job: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  entreprise: PropTypes.string.isRequired,
};

export default TimeLineContainer;
