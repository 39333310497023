import React from 'react';
import Card from '../../components/card/Card';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';

function WebDesign() {
  const { t } = useTranslation();
  return (
    <div className='row sectionBack'>
      <div className='col-md-10'>
        <ScrollAnimation animateIn='fadeInLeft' animateOnce={true}>
          <Card>
            <h5 className='card-title'>{t('skills.webDesign.title')}</h5>
            <h6 className='card-subtitle mb-2 text-muted'>
              {t('skills.webDesign.subTitle')}
            </h6>
            <div className='row'>
              <img
                src='images/iconSkill/illustrator.svg'
                className='img-responsive col-4 col-sm-2'
                alt='logo html'
              />
              <img
                src='images/iconSkill/photoshop.svg'
                className='img-responsive col-4 col-sm-2'
                alt='logo html'
              />
              <img
                src='images/iconSkill/Adobe_XD_CC_icon.svg'
                className='img-responsive col-4 col-sm-2'
                alt='logo html'
              />
            </div>
          </Card>
        </ScrollAnimation>
      </div>
      <div id='iconContainer' className='col-md-2'>
        <i className='fas fa-paint-brush' id='icon'></i>
      </div>
    </div>
  );
}

export default WebDesign;
