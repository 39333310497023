import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import apiKey from '../../services/emailkey';
import { useAlert } from 'react-alert';
import { send } from 'emailjs-com';
import { useTranslation } from 'react-i18next';

const TEST_SITE_KEY = process.env.REACT_APP_SITE_KEY;

function Form() {
  const { t } = useTranslation();
  const recaptchaRef = useRef(null);
  const alert = useAlert();
  const [formValue, setFormValue] = useState({
    lastname: '',
    firstname: '',
    email: '',
    telephone: '',
    message: '',
    errors: [],
  });
  const validation_from = () => {
    let array_errors = [];

    //lastname
    if (formValue.lastname === '') {
      array_errors.push('lastname');
    }
    //firstname
    if (formValue.firstname === '') {
      array_errors.push('firstname');
    }
    //Message
    if (formValue.message === '') {
      array_errors.push('message');
    }
    //email
    const expression = /\S+@\S+/;
    let validEmail = expression.test(String(formValue.email).toLowerCase());
    if (!validEmail) {
      array_errors.push('email');
    }

    setFormValue({
      ...formValue,
      errors: array_errors,
    });

    if (array_errors.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onSubmitAsync = async (event) => {
    event.preventDefault();

    if (validation_from()) {
      const value = await recaptchaRef.current.executeAsync();
      if (value) {
        send(apiKey.SERVICE_ID, apiKey.TEMPLATE_ID, formValue, apiKey.USER_ID)
          .then(() => {
            alert.success(t('contact.sendSucessAlert'));
            setFormValue({
              lastname: '',
              firstname: '',
              email: '',
              telephone: '',
              message: '',
              errors: [],
            });
          })
          .catch(() => {
            alert.error(t('contact.sendErrorAlert'));
          });
      }
    }
  };

  const handleInputChange = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const hasError = (key) => {
    return formValue.errors.indexOf(key) !== -1;
  };

  return (
    <form className='contact-form'>
      <div className='form-row'>
        <div className='col-md-6 mb-3'>
          <label>{t('contact.labelFirstName')}</label>
          <input
            type='text'
            className={
              hasError('lastname') ? 'form-control is-invalid' : 'form-control'
            }
            name='lastname'
            value={formValue.lastname}
            onChange={handleInputChange}
            placeholder='Nom'
          />
          <div className='invalid-feedback'>{t('contact.labelFirstName')}</div>
        </div>
        <div className='col-md-6 mb-3'>
          <label>{t('contact.labelLastName')}</label>
          <input
            type='text'
            className={
              hasError('firstname') ? 'form-control is-invalid' : 'form-control'
            }
            name='firstname'
            value={formValue.firstname}
            onChange={handleInputChange}
            placeholder={t('contact.labelLastName')}
            required
          />
          <div className='invalid-feedback'>{t('contact.invalidLastName')}</div>
        </div>
      </div>
      <div className='form-row'>
        <div className='col-md-6 mb-3'>
          <label>{t('contact.labelEmail')}</label>
          <div className='input-group'>
            <div className='input-group-prepend'>
              <span className='input-group-text' id='inputGroupPrepend3'>
                @
              </span>
            </div>
            <input
              type='text'
              className={
                hasError('email') ? 'form-control is-invalid' : 'form-control'
              }
              name='email'
              value={formValue.email}
              onChange={handleInputChange}
              placeholder={t('contact.labelEmail')}
              required
            />
            <div className='invalid-feedback'>{t('contact.invalidEmail')}</div>
          </div>
        </div>
        <div className='col-md-6 mb-3'>
          <label>{t('contact.labelTel')}</label>
          <input
            type='text'
            className='form-control'
            placeholder={t('contact.labelTel')}
            name='telephone'
            value={formValue.telephone}
            onChange={handleInputChange}
          />
        </div>
        <div className='col-md-12 mb-3'>
          <label>{t('contact.labelMessage')}</label>
          <textarea
            type='text'
            className={
              hasError('message') ? 'form-control is-invalid' : 'form-control'
            }
            name='message'
            value={formValue.message}
            onChange={handleInputChange}
            placeholder=''
            required
            rows='3'
          ></textarea>
          <div className='invalid-feedback'>{t('contact.invalidMessage')}</div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-4'></div>
        <div className='col-md-4 recaptcha'>
          <ReCAPTCHA
            className='g-recaptcha'
            size='invisible'
            ref={recaptchaRef}
            sitekey={TEST_SITE_KEY}
          />
        </div>
        <div className='col-md-4'></div>
      </div>
      <div className='row'>
        <div className='col-md-4'></div>
        <div className='col-md-4'>
          <button
            className='btn button2 container-fluid'
            type='submit'
            onClick={onSubmitAsync}
          >
            {t('contact.ButtonSend')}
          </button>
        </div>
        <div className='col-md-4'></div>
      </div>
    </form>
  );
}

export default Form;
