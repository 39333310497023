import React, { useEffect, useState } from 'react';
import { init } from 'ityped';
import { useTranslation } from 'react-i18next';

function About() {
  const { t } = useTranslation();
  const [cursor1, setCursor1] = useState('cursor');
  const [cursor2, setCursor2] = useState('cusorHidden');
  useEffect(() => {
    const myElement = document.querySelector('#typing1');
    const myElement2 = document.querySelector('#typing2');
    init(myElement, {
      showCursor: false,
      disableBackTyping: false,
      strings: [t('about.intro1')],
      loop: false,
      onFinished: () => (
        // eslint-disable-next-line
        setCursor1('cusorHidden'),
        setCursor2('cusor'),
        init(myElement2, {
          showCursor: false,
          disableBackTyping: false,
          strings: [t('about.intro2')],
          loop: false,
          onFinished: () => {
            setCursor2('cusorHidden');
          },
        })
      ),
    });
  }, [t, setCursor1, setCursor2]);

  return (
    <div id='home' className='parallax-container element'>
      <div className='parallax-background imgBackgroundAbout row' id='content'>
        <div className='layer-filter'>
          <section id='about' className='container-fluid col-md-9'>
            <div className='heading'>
              <h3>
                <span id='typing1'></span>
                <span className={cursor1}>|</span>
              </h3>
              <h1>
                <span id='typing2'></span>
                <span className={cursor2}>|</span>
              </h1>
              <a
                href={process.env.PUBLIC_URL + '/cv-2021.pdf'}
                target='_blank'
                rel='noopener noreferrer'
                className='button2  m-auto'
              >
                {' '}
                {t('about.uploadCv')}
              </a>
            </div>
            <div className='col' id='iconsRs'>
              <a
                className='iconRs'
                href='https://www.linkedin.com/in/naleny-nadessane/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fab fa-linkedin'></i>
              </a>
              <a
                className='iconRs'
                href='https://twitter.com/NNaleny'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fab fa-twitter-square'></i>
              </a>
              <a
                className='iconRs'
                href='https://www.facebook.com/naleny/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fab fa-facebook-square'></i>
              </a>
              <a
                className='iconRs'
                href='https://www.instagram.com/naleny/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fab fa-instagram'></i>
              </a>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default About;
