import React from 'react';
import TimeLineContainer from '../../components/timeline/TimeLineContainer';
import { useTranslation } from 'react-i18next';

function TimeLine() {
  const { t } = useTranslation();
  return (
    <div className='main-timeline'>
      <TimeLineContainer
        entreprise={t('experience.timeLine1.entreprise')}
        job={t('experience.timeLine1.job')}
        date={t('experience.timeLine1.date')}
      >
        <p>{t('experience.timeLine1.desc1')}</p>
        <p>{t('experience.timeLine1.desc2')}</p>
        <p>{t('experience.timeLine1.desc3')}</p>
        <p>{t('experience.timeLine1.desc4')}</p>
        <p>{t('experience.timeLine1.desc5')}</p>
      </TimeLineContainer>
      <TimeLineContainer
        entreprise={t('experience.timeLine2.entreprise')}
        job={t('experience.timeLine2.job')}
        date={t('experience.timeLine2.date')}
      >
        <p>{t('experience.timeLine2.desc1')}</p>
        <p>{t('experience.timeLine2.desc2')}</p>
      </TimeLineContainer>
      <TimeLineContainer
        entreprise={t('experience.timeLine3.entreprise')}
        job={t('experience.timeLine3.job')}
        date={t('experience.timeLine3.date')}
      >
        <p>{t('experience.timeLine3.desc1')}</p>
      </TimeLineContainer>
      <TimeLineContainer
        entreprise={t('experience.timeLine4.entreprise')}
        job={t('experience.timeLine4.job')}
        date={t('experience.timeLine4.date')}
      >
        <p>
          {t('experience.timeLine4.desc1')}
          <br />
          <a href='http://opti-monde.com'>
            {t('experience.timeLine4.desc2')}
          </a>{' '}
        </p>
        <p>{t('experience.timeLine4.desc3')}</p>
        <p>{t('experience.timeLine4.desc4')}</p>
        <p>{t('experience.timeLine4.desc5')}</p>
      </TimeLineContainer>
      <TimeLineContainer
        entreprise={t('experience.timeLine5.entreprise')}
        job={t('experience.timeLine5.job')}
        date={t('experience.timeLine5.date')}
      ></TimeLineContainer>
      <TimeLineContainer
        entreprise={t('experience.timeLine6.entreprise')}
        job={t('experience.timeLine6.job')}
        date={t('experience.timeLine6.date')}
      ></TimeLineContainer>
      <TimeLineContainer
        entreprise={t('experience.timeLine7.entreprise')}
        job={t('experience.timeLine7.job')}
        date={t('experience.timeLine7.date')}
      ></TimeLineContainer>
      <TimeLineContainer
        entreprise={t('experience.timeLine8.entreprise')}
        job={t('experience.timeLine8.job')}
        date={t('experience.timeLine8.date')}
      ></TimeLineContainer>
    </div>
  );
}

export default TimeLine;
