import React from 'react';
import TimeLine from '../../containers/experiences/TimeLine';
import { useTranslation } from 'react-i18next';

function Experience() {
  const { t } = useTranslation();
  return (
    <section className='section section-dark' id='experience'>
      <div className='red-divider'></div>
      <div className='heading'>
        <h2>{t('experience.title')}</h2>
      </div>
      <TimeLine></TimeLine>
    </section>
  );
}

export default Experience;
