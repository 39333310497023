import React from 'react';
import PropTypes from 'prop-types';

function Card({ children }) {
  return (
    <div className='card'>
      <div className='card-body'>{children}</div>
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.array.isRequired,
};

export default Card;
