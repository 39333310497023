import React from 'react';
import Home from './containers/others/Home';
import './App.css';
import { positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { init } from 'emailjs-com';
import apiKey from './services/emailkey';

init(apiKey.USER_ID);

const options = {
  timeout: 5000,
  position: positions.TOP_RIGHT,
};

function App() {
  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Home></Home>
    </AlertProvider>
  );
}

export default App;
