import React from 'react';
import { useTranslation } from 'react-i18next';

function Portfolio() {
  const { t } = useTranslation();
  return (
    <section id='portfolio' className='section section-dark'>
      <div className='container'>
        <div className='white-divider'></div>
        <div className='heading'>
          <h2>{t('portfolio.title')}</h2>
        </div>
        <div className='row row-portfolio'>
          <div className='portfolio-container col-md-6'>
            <div className='box'>
              <div className='thumbnail'>
                <img src='images/portfolio/snake.png' alt='Jeu snake' />
                <div>
                  <a
                    data-id='1'
                    href='jeu/jeuserpent/index.html'
                    className='link'
                    target='_blank'
                  >
                    <span>+</span>
                  </a>
                </div>
              </div>
              <div className='text-box'>
                <strong className='title text-left no-bg padding-zero'>
                  {t('portfolio.projet1.title')}
                </strong>
                <p>{t('portfolio.projet1.desc')}</p>
              </div>
            </div>
          </div>
          <div className='portfolio-container col-md-6'>
            <div className='box'>
              <div className='thumbnail'>
                <img src='images/portfolio/jeuMario.png' alt='Jeu Mario' />
                <div>
                  <a
                    data-id='1'
                    href='jeu/jeuMario/jeuMario.jar'
                    download
                    className='link'
                  >
                    <span>+</span>
                  </a>
                </div>
              </div>
              <div className='text-box'>
                <strong className='title text-left no-bg padding-zero'>
                  {t('portfolio.projet2.title')}
                </strong>
                <p>{t('portfolio.projet2.desc')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='portfolio-container col-md-6 container-fluid'>
            <div className='box'>
              <div className='thumbnail'>
                <img
                  src='images/portfolio/optimonde2.png'
                  alt='Blog opti-monde'
                />
                <div>
                  <a
                    data-id='1'
                    href='https://opti-monde.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='link'
                  >
                    <span>+</span>
                  </a>
                </div>
              </div>
              <div className='text-box'>
                <strong className='title'>
                  {t('portfolio.projet3.title')}
                </strong>
                <p>{t('portfolio.projet3.desc')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
