import React from 'react';
import Card from '../../components/card/Card';
import { DesktopMac } from '@material-ui/icons/';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';

function FrontEnd() {
  const { t } = useTranslation();
  return (
    <div className='row sectionFront'>
      <div className='col-12 col-md-10'>
        <ScrollAnimation animateIn='fadeInLeft' animateOnce={true}>
          <Card>
            <h5 className='card-title'> {t('skills.frontEnd.title')}</h5>
            <h6 className='card-subtitle mb-2 text-muted'>
              {t('skills.frontEnd.title')}
            </h6>
            <div className='row'>
              <img
                src={process.env.PUBLIC_URL + 'images/iconSkill/html5.png'}
                className='img-responsive col-4 col-sm-2'
                alt='logo html'
              />
              <img
                src='images/iconSkill/css3.png'
                className='img-responsive col-4 col-sm-2 '
                alt='logo html'
              />
              <img
                src='images/iconSkill/js.png'
                className='img-responsive col-4 col-sm-2'
                alt='logo html'
              />
              <img
                src='images/iconSkill/bootstrap.png'
                className='img-responsive col-4 col-sm-2'
                alt='logo html'
              />
              <img
                src='images/iconSkill/react.png'
                className='img-responsive col-4 col-sm-2'
                alt='logo html'
              />
              <img
                src='images/iconSkill/vuejs.png'
                className='img-responsive col-4 col-sm-2'
                alt='logo html'
              />
            </div>
          </Card>
        </ScrollAnimation>
      </div>

      <div id='iconContainer' className='col col-md-2 container-sm'>
        <DesktopMac id='iconDesktop' />
      </div>
    </div>
  );
}

export default FrontEnd;
