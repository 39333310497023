import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();
  return (
    <footer className='sticky-footer'>
      <div className='container my-auto'>
        <div className='copyright text-center my-auto'>
          <span>
            {t('footer.text')} <i className='fas fa-heart'></i>{' '}
            {t('footer.name')}{' '}
          </span>
          <br />
          <span>
            {t('footer.copyright')} &copy; {new Date().getFullYear()}
          </span>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
