import React from 'react';
import PropTypes from 'prop-types';

function BreakSection({ className, children }) {
  return (
    <div className={className}>
      <div className='layer-filter'>{children}</div>
    </div>
  );
}

BreakSection.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.element,
};

export default BreakSection;
