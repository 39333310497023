import React from 'react';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

function Topbar() {
  const { t } = useTranslation();
  return (
    <div>
      <nav className='navbar navbar-expand-lg navbar-dark'>
        {/* <a className="navbar-brand" href="#">Logo</a> */}
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='true'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'>
            <i className='fab fa-bars menu-icon'></i>
          </span>
        </button>
        <div
          className='collapse navbar-collapse justify-content-end'
          id='navbarSupportedContent'
        >
          <ul className='navbar-nav'>
            <li className='nav-link'>
              <Link
                activeClass='active'
                className='test7'
                to='home'
                spy={true}
                smooth={true}
                duration={500}
              >
                {t('navBar.home')}
              </Link>
            </li>
            <li className='nav-link'>
              <Link
                activeClass='active'
                className='test7'
                to='skills'
                spy={true}
                smooth={true}
                duration={500}
              >
                {t('navBar.skills')}
              </Link>
            </li>
            <li className='nav-link'>
              <Link
                activeClass='active'
                className='test7'
                to='experience'
                spy={true}
                smooth={true}
                duration={500}
              >
                {t('navBar.experience')}
              </Link>
            </li>
            <li className='nav-link'>
              <Link
                activeClass='active'
                className='test7'
                to='education'
                spy={true}
                smooth={true}
                duration={500}
              >
                {t('navBar.education')}
              </Link>
            </li>
            <li className='nav-link'>
              <Link
                activeClass='active'
                className='test7'
                to='portfolio'
                spy={true}
                smooth={true}
                duration={500}
              >
                {t('navBar.portfolio')}
              </Link>
            </li>
            <li className='nav-link'>
              <Link
                activeClass='active'
                className='test7'
                to='contactme'
                spy={true}
                smooth={true}
                duration={500}
              >
                {t('navBar.contact')}
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Topbar;
